.header {
    width: 100%;
}

.header__content {
    width: 100%;
    max-width: 90%;
    padding: 20px 0;
    margin: 0 auto;
    background: #fff;
    overflow: hidden;
}

.header__logo {
    max-width: 100%;
    display: block;
}

.header__navbar {
    width: 100%;
    background: $darkBlue;
}

.header__navbar-inner {
    width: 90%;
    color: $white;
    margin: 0 auto;
    height: 62px;
    line-height: 62px;
    position: relative;

    @include below(640px) {
        width: 100%;
    }
}

.header__navbar-menu {
    height: 100%;
    display: table;
    cursor: pointer;
    padding: 0 10px;
    transition: background .1s ease-in-out;

    .header__navbar-globe {
        top: 2px;
        position: relative;
    }

    &:hover {
        background: $lightBlue;
    }

    &.is-open {
        background: $lightBlue;
    }
}
