.boxes {
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
    padding: 45px 0;
    display: flex;
    flex-wrap: wrap;
}

.box {
    width: 25%;
    padding: 10px;
    color: #000;
    display: block;
    flex: 0 0 auto;
    text-decoration: none;
    backface-visibility: hidden;
    transition: color .1s ease-in-out, transform .2s ease-in-out;

    &:hover {
        color: #fff;
        transform: translateY(-3px);

        .box__inner {
            background: $lightBlue;
        }
        h1.box__title {

        }

        h1.box__title:after {
            animation: fadeArrow 0.3s ease-in-out forwards;
        }
    }

    @include below(768px) {
        width: 33.33%;

        .box__title {
            font-size: 20px;
        }
    }
    @include below(500px) {
        width: 50%;
    }
}

.box__inner {
    width: 100%;
    display: table;
    min-height: 189px;
    text-align: center;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    position: relative;
    border: 1px solid #E3E3E3;
    box-shadow: 0 7px 10px -3px rgba(0,0,0,.1);
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #f2f2f2 0%, #d9d9d9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f2f2f2 0%,#d9d9d9 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f2f2f2 0%,#d9d9d9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d1d1d1',GradientType=0 ); /* IE6-9 */
}

.box__title {
    font-size: 24px;
    line-height: 29px;
    display: table-cell;
    vertical-align: middle;
    word-break: break-word;
    position: relative;

    &:after {
        color: #fff;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        content: "\2192";
        position: absolute;
        font-size: 28px;
    }
}

@keyframes fadeArrow {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
