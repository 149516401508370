.footer {
    width: 100%;
    bottom: 0;
    z-index: 0;
    position: absolute;
    background: $darkBlue;

    @include below(700px) {
        position: relative;
    }
}

.footer__inner {
    width: 90%;
    color: $white;
    margin: 0 auto;
    height: 62px;
    text-align: center;

    @include below(700px) {
        height: auto;
        width: 100%;
        padding: 15px 0 0 0;
    }
}

.footer__text {
    @include vertical-align();

    @include below(700px) {
        transform: none;
    }
}

.footer_link {
    font-size: 16px;
    padding: 0 15px;
    color: #fff;
    position: relative;
    text-decoration: none;
    display: inline-block;

    &:after {
        content: '';
        width: 2px;
        height: 20px;
        top: 0;
        right: 0;
        margin-left: 15px;
        background: #fff;
        position: absolute;
        display: inline-block;
    }
       
    &:last-child:after{
        display:none;
    }
    
    &:hover {
        text-decoration: underline;
    }

    @include below(700px) {
        display: block;
        margin: 15px 0;

        &:after {
            display: none;
        }
    }
}

.footer__icons {
    margin-left: 10px;
    display: inline-block;

    @include below(700px) {
        display: block;
        margin-left: 0;
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid #fff;
    }
}

.footer__icon {
    width: 20px;
    height: 20px;
    margin: 0 3px;
    font-size: 0;
    display: inline-block;
    text-decoration: none;
    position: relative;

    img {
        display: inline-block;
        transition: transform .1s ease-in-out;
    }

    &:hover {
        img {
            transform: translateY(-2px);
        }
    }

    @include below(700px) {
        width: 50px;
        height: 50px;
    }
}

.back-to-top {
    width: 42px;
    height: 45px;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    display: block;
    position: absolute;
    bottom: 57px;
    cursor: pointer;
    text-align: center;
    background: $darkBlue;

    img {
        @include vertical-align();
    }

    &:hover {
        img {
            animation: backTopArrow 1s ease-in-out infinite;
        }
    }

    @include below(700px) {
        top: -40px;
        bottom: auto;
    }
}

@keyframes backTopArrow {
    0% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(-70%);
    }
    100% {
        transform: translateY(-50%);
    }
}
