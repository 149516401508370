@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i');

html {
    height: 100%;
}

body {
    padding-bottom: 62px;
    min-height: 100%;
    position: relative;
    font-family: 'Open Sans', sans-serif;

    @include below(700px) {
        padding-bottom: 0;
    }
}

.stripe {
    width: 20%;
    height: 5px;
    float: left;
    display: block;

    &--darkBlue {
        background: $darkBlue;
    }
    &--lightBlue {
        background: $lightBlue;
    }
    &--yellow {
        background: $yellow;
    }
    &--gray {
        background: $gray;
    }
}

// Dont print elements
// with this class.
@media print {
    .no-print {
        display: none !important;
    }
}
