.default-content {
    width: 90%;
    margin: 75px auto;
    max-width: 910px;

    h2 {
        margin-top: 30px;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.5px;
        font-weight: bold;
    }

    p {
        margin: 20px 0;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
    }

    ul {
        margin-left: 4%;
        margin-top: 20px;

        li {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.5px;
        }
    }

    a {
        color: #468EE5;
        transition: color .1s ease-in-out;

        &:hover {
            color: rgba(#468EE5, 0.6);
        }
    }
}

.default-content__top {
    float: left;
    width: 90%;
    max-width: 780px;
    margin-bottom: 10px;

    @include below(768px) {
        width: 100%;
        max-width: 910px;
    }
}

.default-content__inner {
    max-width: 780px;
    display: inline-block;
}

.default-content__title {
    color: $darkBlue;
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;

    @include below(500px) {
        font-size: 24px;
        line-height: 28px;
    }
    @include below(321px) {
        font-size: 22px;
        line-height: 27px;
    }
}

.default-content__preamble p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.default-content__footer {
    margin: 80px 0 140px 0;

    @include below(700px) {
        margin: 50px 0 0 0;
    }
}

.default-content__footer-logo {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.default-content__footer-text p {
    font-size: 18px;
    line-height: 28px;

    @include below(700px) {
        text-align: center;
    }
}

.default-content__sidebar {
    float: right;
    width: 10%;
    display: block;
    margin-top: 40px;

    .dl-icon {
        display: inline-block;
    }

    @include below(900px) {
        .pdf-icon {
            padding-left: 0px;
        }
    }
    @include below(768px) {
        display: none;
    }
}

.default-content__bottom-icons {
    @include below(640px) {
        display: none;
    }
}

.dl-icon {
    cursor: pointer;
    transition: transform .1s ease-in-out;

    &:hover {
        transform: translateY(-3px);
    }
}

.pdf-icon {
    padding-left: 10px;
}

.default-content__footer-text {
    text-align: center !important;

    p {
        text-align: center !important;
    }
}
