.dropdown {
    left: 0;
    width: 100%;
    opacity: 0;
    position: absolute;
    z-index: 20;
    background: $lightBlue;
    visibility: hidden;
    transition: opacity .2s ease-in-out;

    &.is-open {
        opacity: 1;
        visibility: visible;
        background: $lightBlue;
    }
}

.header__navbar-menu:hover {
    .dropdown {
        opacity: 1;
        visibility: visible;
    }
}

.dropdown__inner {
    width: 100%;
    padding: 20px;
    position: relative;
}

.dropdown__nav {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include clearfix();

    a {
        color: #fff;
        width: 25.5%;
        flex: 1 auto;
        display: block;
        font-size: 16px;
        position: relative;
        line-height: normal;
        padding-bottom: 15px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        @include below(640px) {
            width: 100%;
        }
    }
}

.hamburger {
    right: 5%;
    width: 30px;
    display: none;
    @include vertical-align(absolute);

    @include below(640px) {
        display: block;
    }

    &.is-open {
        .hamburger__stripe {
            &:first-of-type {
                top: -13px;
            }
            &:last-of-type {
                top: 13px;
            }
        }
    }
}

.hamburger__stripe {
    width: 100%;
    height: 5px;
    display: block;
    background: #fff;
    position: absolute;
    border-radius: 15px;
    transition: top 0.2s ease-in-out;

    &:first-of-type {
        top: -10px;
    }
    &:last-of-type {
        top: 10px;
    }
}
